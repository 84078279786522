import React from 'react';
import useToggle from '../../Hooks/useToggle';import { Helmet } from 'react-helmet';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';import icuamb from '../fleetimg/icuamb.png';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import FaqHome from '../HomeTwo/FaqHome';
import { Fleet } from '../HomeTwo/Fleet';
import ServicesHomeTwo from '../HomeTwo/ServicesHomeTwo';
import { FaAmbulance } from 'react-icons/fa';
import '../HomeTwo/Fleet.css';
import Allambstabs from './Allambstabs';

export default function Icu() {
    const [drawer, drawerAction] = useToggle(false);
  return (
    <>
             <Helmet>
        <title>Siren24 | ICU Care Ambulance</title>
        <meta name="title" content="ICU Care ambulance booking app"/>
<meta name="description" content="Ambulances equipped with portable ventilators or automatic transport ventilators, which normally incorporate a life support system and are supervised by paramedics, are known as ventilator ambulances."/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service, icu care ambulance"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>

                <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} /><br/><br/><br/><br/><br/>

            <div className="fleetflex-container">
        <div className="flex-item-right">
          <h1><a href='https://sjtrem.biomedcentral.com/articles/10.1186/s13049-021-00896-0' style={{textDecoration:'none', color:'black'}}>ICU Care Ambulance</a></h1><br/>
          <h3>Get an Ambulance at your doorstep</h3>
          <p style={{fontSize:'16px',paddingTop:'5px',marginLeft:'25px'}}>Purpose: to transport patients who require advanced medical supervision.When to ask for these: during severe situations like - cardiac arrest, brain haemorrhage, major fractures, critical accidents etc.</p>
          <div className='appie-hero-content appie-download-content'>
                                <ul >
                                <li >
                                        <a href="https://siren24-app.web.app/" >
                                          
                                            <FaAmbulance style={{color:'black', fontSize:'30px'}}/>
                                            <span style={{fontSize:'20px', paddingtop:'1px'}}>
                                            &nbsp;Book Now   <span> Ambulace</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="https://play.google.com/store/apps/details?id=com.yoursuperidea.siren24&hl=en_IN&gl=US">
                                            <i className="fab fa-google-play" />
                                            <span>
                                                Download for <span>Android</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                                
                                </div>
        </div>
        <div >
          <img src={icuamb} style={{ width:'280px'}} className='indimgserv'></img>
        </div>
      </div><br/><br/>

            <ServicesHomeTwo />
            <Allambstabs/>
                        <FaqHome/>
            <FooterHomeTwo />
            <BackToTop className="back-to-top-2" />
    </>
  )
}
