import React from 'react'

export default function ShareIcons() {
  return (
   <>
                   <div className="post-share container">
                    <h5>Share:</h5>
                    <a className="fac" href="https://www.facebook.com/pages/category/Safety---First-Aid-Service/Siren24-108257058227358/">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="twi" href="https://twitter.com/Chandra56939310">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a className="goo" href="https://www.linkedin.com/in/siren-ambulance-413a7a219/?originalSubdomain=in">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </div>
   </>
  )
}
