import React from 'react';
import img1 from '../fleetimg/img1.png';
import airamb from '../fleetimg/airamb.png';
import patamb from '../fleetimg/patamb.png';
import trainamb from '../fleetimg/trainamb.png';
import icuamb from '../fleetimg/icuamb.png';
import basicamb from '../fleetimg/basicamb.png';
import deadamb from '../fleetimg/deadamb.png';
import { Link } from 'react-router-dom';
const data = [
    {
        image: airamb,
        heading: "Air ambulance",
        subHeading: "Get ambulance at your doorstep",
        link:"/air-ambulance",
        content: "Purpose: to transport patients from one place to another far away place. When to ask for these: during severe situations when a patient has to be air lifted for early access to advanced medication."
    },
    {
        image: trainamb,
        heading: "Train ambulance",
        subHeading: "Get ambulance at your doorstep",
        link:"/train-ambulance",
        content: "Purpose: to transport patients from one place to another far away place. When to ask for these: during severe situations when a patient has to be train lifted for early access to advanced medication."
    },
    {
        image: basicamb,
        heading: "Basic Life Support Ambulance",
        subHeading: "Get ambulance at your doorstep",
        link:"/basic-life-support-ambulance",
        content: "Purpose: to transport patients who require basic medical supervision.When to ask for these: during minor situations such as small fractures and visits to nursing homes for check-ups etc."
    },
    {
        image: icuamb,
        heading: "ICU Care ambulance",
        subHeading: "Get ambulance at your doorstep",
        link:"/icu-care-ambulance",
        content: "Purpose: to transport patients who require advanced medical supervision.When to ask for these: during severe situations like - cardiac arrest, brain haemorrhage, major fractures, critical accidents etc."
    },
    {
        image: patamb,
        heading: "Patient transport",
        subHeading: "Get ambulance at your doorstep",
        link:"/patient-transport-ambulance",
        content: "Purpose: to transport patients who have to be transported to and from one hospital to another or from home to the hospital."
    },
    {
        image: deadamb,
        heading: "Dead body ambulance",
        subHeading: "Get ambulance at your doorstep",
        link:"/dead-body-ambulance",
        content: "Purpose: to transport the dead body of a person to the place of last rites. When to ask for these: during transport of the dead body from home to the crematorium or from hospital to the crematorium."
    },

]
export default function Allambstabs() {
  return (
   <>

<div className='container'>
    <h1 style={{textAlign:'center', fontWeight:'70'}}>All types of Ambulances at Siren24</h1><br/> 
    <p style={{textAlign:'center'}}>Your One Step Solution For 24*7 Ambulance Service At Your Doorstep!</p><br/>
<div className="row" >
                     
                     {
  data.map((item)=>
      <div className="col-lg-4 col-md-6">
      <div
          className="appie-single-service-2 item-3 mt-0 wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="600ms"
      >
          <div className="icon">
          <img  src={item.image} style={{width:'180px'}}></img>

          </div>
          <h4 className="title" style={{textAlign:'center',fontWeight:'400'}}>{item.heading} </h4>
          <p style={{textAlign:'center'}}>
          {item.subHeading}
          </p>
          <h6 style={{textAlign:'center', fontWeight:'50'}}><Link to={item.link} style={{background:'red',color:'white',padding:'4px 10px',boxShadow:'1px 1px 10px gray'}}>Know more</Link></h6>
          
      </div>
      <br/> <br/>
  </div>
  
  )
 }
                     
                   
                  </div>
</div>
   </>
  )
}
