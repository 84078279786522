import React from 'react';
import thumb from '../../assets/images/service-details-thumb.jpg';
import counterIconOne from '../../assets/images/icon/counter-icon-1.svg';
function DetailsService() {
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="content">
                               
                                    <h3 className="title">  The range of services provided by Siren24 are -</h3><br/> 

                                  

                        <div className="col-lg-12 col-md-12 mb-md-12">
                            <div
                                className="appie-single-counter mt-30 wow animated fadeInUp"
                                
                            >
                                <div className="counter-content">
                                <div className="icon">
                                    
                                    <i className="fas fa-bolt" />
                                </div>
                                  
                                    <h3 className="title"> Various types of Ambulances </h3> <p> Emergency and Non-Emergency like - ICU Ambulance, Mortuary Ambulance, Basic Ambulance, etc.</p>
                                </div>
                            </div>
                        </div><br/> <br/> <br/> <br/> <br/> <br/> 

                        
                        <div className="col-lg-12 col-md-12 mb-md-12">
                            <div
                                className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                                
                            >
                                <div className="counter-content">
                                <div className="icon">
                                    
                                    <i className="fas fa-bolt" />
                                </div>
                                    <h3 className="title">
                                    	Health Professionals on Call  
                                        
                                    </h3>
                            <p>to guide, advise and monitor the patient’s health while in the ambulance.</p>
                                </div>
                            </div>
                        </div><br/> <br/> <br/> <br/> <br/> <br/> 

                        <div className="col-lg-12 col-md-12 mb-md-12">
                            <div
                                className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                                
                            >
                                <div className="counter-content">
                                <div className="icon">
                                    
                                    <i className="fas fa-bolt" />
                                </div>
                                   
                                    <p><h3 className="title">Insurance Company Services </h3> <br/> 
                           <p className='servicetxt'>        Siren24 has introduced an affordable package Ambulance Services for Insurance Companies  in India.<br></br> Various features of such Corporate Services are -</p> 
<div className='servicelistdiv'>
<li>Any registered Insurance Company  can tie up with Siren24 for such ambulance services.</li>
<li>Siren24 would provide 24*7 ambulance support to the insurance holders as well as the insurance company &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;employees.</li>
<li>The services would be provided with respect to the hospitals and nursing centres mentioned in the insurance &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;holders account.</li>
<li>There would be different affordable subscription plans available at a rate less than the market rate which can be &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;chosen by the company so as to provide immediate healthcare to the insurance holders. </li>
<li>They would be provided with emergency as well as non-emergency ambulance services.</li>
<li>The Ambulances would be equipped with all necessary medical equipment and health professionals.</li>
<li>Live Location Tracking of the ambulance feature would also be available with the patient and the attendant.</li>
<li>The services are as of now available for all the registered insurance companies in Delhi, Noida, Ghaziabad, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Faridabad and Gurgaon. Though there will soon be expansion of such services in other states as well.</li>
<br></br><p className='servicetxtcall'>In case of any query, give us a call on +919910295915</p>

</div>

                                    </p>
                                </div>
                            </div><br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/><br/> <br/> <br/> <br/>  <br/> <br/> <br/> <br/>
                        </div>


                        <div className="col-lg-12 col-md-12 mb-md-12">
                            <div
                                className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                                
                            >
                                <div className="counter-content">
                                <div className="icon">
                                    
                                    <i className="fas fa-bolt" />
                                </div>
                                    <h3 className='title'>Corporate Services </h3><p className='servicetxt'>Siren24 has introduced an affordable package Ambulance Services for Corporates in India. <br></br>Various features of such Corporate Services are-</p>

                                    <div className='servicelistdiv'>
                                    <li>Any size of Corporate offices and businesses can tie up with Siren24 for the ambulance services.</li>
                                    <li>Siren24 would provide 24*7 ambulance support to the company workers and their immediate family members.</li>
                                    <li>The services would be provided during as well as after the working hours as well.</li>
                                    <li>There would be different affordable subscription plans which can be chosen by the company so as to provide &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;immediate healthcare to its employees and their family in times of emergency.</li>
                                    <li>They would be provided with emergency as well as non-emergency ambulance services.</li>
                                    <li>The Ambulances would be equipped with all necessary medical equipment and health professionals.</li>
                                    <li>Live Location Tracking of the ambulance feature would also be available with the patient and the attendant.</li>
                                    <li>The services are as of now available for all the corporates in Delhi, Noida, Ghaziabad, Faridabad and Gurgaon.  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Though there will  soon be expansion of such services in other states as well.</li>
                                    <br/> <br/><p className='servicetxtcall'>In case of any query, give us a call on +919910295915</p>
                                    </div>
                                </div>
                            </div><br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> 
                        </div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
