import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';import { Helmet } from 'react-helmet';
import Drawer from '../Mobile/Drawer';
import DetailsService from './DetailsService';import SignupHomeEight from '../HomeEight/SignupHomeEight';
import HeroService from './HeroService';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';import HeroAbout from '../AboutUs/HeroAbout';
import ServicesAbout from '../AboutUs/ServicesAbout';import SponserHomeTwo from '../HomeTwo/SponserHomeTwo';
import counterIconOne from '../../assets/images/icon/counter-icon-1.svg';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
         <Helmet>
        <title>Siren24 | Corporate Services</title>
        <meta name="title" content="siren24 Corporate Services"/>
<meta name="description" content="Siren24 has introduced an affordable package Ambulance Services for Corporates in India. "/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <HeroAbout />
            <ServicesAbout />
            <section className="appie-service-details-area pt-50 pb-100">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="content">
                               
                       

                        <div className="col-lg-12 col-md-12 mb-md-12">
                            <div
                                className="servicecontbox"
                                
                            >
                                <div className="counter-content">
                                <div className="icon">
                                    
                                    <i className="fas fa-bolt" />
                                </div>
                                    <h3 className='title'>Corporate Services </h3><p className='servicetxt'>Siren24 has introduced an affordable package Ambulance Services for Corporates in India. <br></br>Various features of such Corporate Services are-</p>

                                    <div className='servicelistdiv'>
                                    <li>Any size of Corporate offices and businesses can tie up with Siren24 for the ambulance services.</li>
                                    <li>Siren24 would provide 24*7 ambulance support to the company workers and their immediate family members.</li>
                                    <li>The services would be provided during as well as after the working hours as well.</li>
                                    <li>There would be different affordable subscription plans which can be chosen by the company so as to provide &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;immediate healthcare to its employees and their family in times of emergency.</li>
                                    <li>They would be provided with emergency as well as non-emergency ambulance services.</li>
                                    <li>The Ambulances would be equipped with all necessary medical equipment and health professionals.</li>
                                    <li>Live Location Tracking of the ambulance feature would also be available with the patient and the attendant.</li>
                                    <li>The services are as of now available for all the corporates in Delhi, Noida, Ghaziabad, Faridabad and Gurgaon.  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Though there will  soon be expansion of such services in other states as well.</li>
                                    </div>
                                </div>
                            </div><br/> <br/> <br/>
                        </div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <SponserHomeTwo /> */}
            <SignupHomeEight />
            <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default Service;
