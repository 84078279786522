import React from "react";
import HeaderHomeTwo from "../HomeTwo/HeaderHomeTwo";
import BackToTop from "../BackToTop";
import FooterHomeTwo from "../HomeTwo/FooterHomeTwo";
import ShareIcons from "./ShareIcons";
import { Helmet } from "react-helmet";
import HeroNews from "./HeroNews";
function Blog() {
  return (
    <>
      <Helmet>
        <title>
          Siren24 | In Emergency, No Need To Wait for an hour, When there is
          Siren24
        </title>
        <meta
          name="title"
          content="In Emergency, No Need To Wait for an hour, When there is Siren24"
        />
        <meta name="description" content="Siren24 application has the power to fade away life destructing
          factors which makes the obstacle over the reach of an ambulance to
          your special ones." />
        <meta
          name="keywords"
          content="ambulance, ambulance app, ambulance service, ambulance number, ambulance news"
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English"></meta>
      </Helmet>
      <HeaderHomeTwo />
      <HeroNews
        title="In Emergency, No Need To Wait for an hour, When there is Siren24"
        breadcrumb={[
          { link: "/home", title: "home" },
          { link: "/news", title: "News" },
        ]}
      />
      <br />
      <br />
      <br />
      <div className="container">
        <p></p>
        <br />
        <h4 className="article-title">
          In Emergency, No Need To Wait for an hour, When there is Siren24
        </h4>
        <p>
          The term emergent basically known as an act of unexpected situation in
          near future.When the situation is unexpected then our mind also act in
          an abnormal way and this can make the situation even more worse than
          it actually gonna happen. We all are in the pandemic era of COVID-19
          from one and a half year which makes the term emergency a legal
          obligation over our minds as the cases rising day by day of new
          variants with new faces ,so in this global situation ‘Ambulance’ must
          be a key factor for immediate treatment to the patient ,but what about
          it’s reach? Does each and every person have a reach over an ambulance
          when it comes to urgency and time factor?Maybe not because there are
          many lives gone only due to improper reach of the same(ambulance).
          <br />
          But now there is no need to worry about this panicking matter at all!
          As our team is here with an application which contains the sense of
          need as well as feed “Siren24”.
          <br />
          As the name itself suggest the meaning:
          <br />
          <li>Siren-sounds that alert.</li>
          <li>24-available as per your need.</li>
          You all definitely have a thought in your mind that why there is an
          online ambulance booking app?We all get it over our phone calls ,but
          what about these problems below:
          <br />
          <li>Traffic</li>
          <li>Paramedic’s response</li>
          <li>Vehicle conditions</li>
          <li>Climate</li>
          <li>Bottom line</li>
          <br />
          But our application has the power to fade away these life destructing
          factors which makes the obstacle over the reach of an ambulance to
          your special ones.
          <br />
          It’s features will clear your thoughts ,have a look over them:
          <br />
          <li>Ambulance as per the situation-BLS, ACLS, BIKE, AIR, PTV.</li>
          <li>Directly book from your place.</li>I
          <li>mmediate response from nearby ambulance. </li>
          <br />
          So if you really want a immediate life secure hack then “Siren24” must
          be in your icon collection.
        </p>
        <br />
        <br />
        <ShareIcons /> <br />
        <br />
      </div>
      <div className="comment-form-area container py-md-6">
        <h3>Post a Comment</h3>
        <p>
          Your email address will not be published. Required fields are marked
        </p>
        <div className="comment-form">
          <form className="row" action="#" method="post">
            <div className="col-md-6">
              <input type="text" name="name" placeholder="Name" />
            </div>
            <div className="col-md-6">
              <input type="email" name="email" placeholder="Email" />
            </div>
            <div className="col-md-12">
              <input type="url" name="url" placeholder="Website" />
            </div>
            <div className="col-md-12">
              <div className="save-comment">
                <input id="history-name" type="checkbox" />
                <label htmlFor="history-name">
                  Save my name, email, and website in this browser for the next
                  time I comment.
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <textarea placeholder="Coment"></textarea>
            </div>
            <div className="col-md-12">
              <button type="submit">Post Comment</button>
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />
      <br />
      <FooterHomeTwo />
      <BackToTop />
    </>
  );
}

export default Blog;
