import React from 'react';
function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Why To Choose Our <a href='https://en.wikipedia.org/wiki/Ambulance' style={{textDecoration:'none', color:'black'}}>Ambulance Service</a></h3>
                                <p>
                                Siren24 is a 24*7 Ambulance Booking Service platform equipped with all necessary medical aid and health professionals.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    
                                    <i className="fas fa-bolt" />
                                </div>
                                <h4 className="title">Quick Ambulance Booking</h4>
                                <p>
                                 Ambulance booking now in just 60 seconds
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                <i class="fas fa-clock"></i>
                                
                                </div>
                                <h4 className="title">24*7 Service </h4>
                                <p>
                                Book at anytime of the day
                                <br/><br/>
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-ambulance" />

                                </div>
                                <h4 className="title">Variety of Ambulances </h4>
                                <p>
                                Choose the one your patient needs the most
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-briefcase-medical" />
                                </div>
                                <h4 className="title">On-Ambulance Facilities </h4>
                                <p>
                                Providing necessary medical aid and health professionals.
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-forward" />
                                </div>
                                <h4 className="title">Readily Available </h4>
                                <p>
                                To save you the long waiting time for an ambulance
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-phone" />
                                </div>
                                <h4 className="title">On-Call Communication </h4>
                                <p>
                                Engage effortlessly with ambulance drivers over the phone.
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-7 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-money-bill-wave" />
                                </div>
                                <h4 className="title">Affordable! </h4>
                                <p>
                                Book without burning a hole in your pocket.
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-8 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-network-wired" />
                                </div>
                                <h4 className="title">Strong Network </h4>
                                <p>
                                2000+ validated private ambulance networks across India.
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-9 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-location" />
                                </div>
                                <h4 className="title">LIve Location Tracking  </h4>
                                <p>
                                Track the live location of your ambulance.
                                </p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
