import React from 'react';
import signupThumb from '../../assets/images/signup-thumb.png';

function SponserHomeEight({ className }) {
    return (
        <>
            <div className={`appie-signup-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-signup-box">
                                <span>Siren24</span>
                                <h3 className="title">Get Started.</h3>
                               <br/>
                               <h3 style={{color:'white'}}>In case of any query, give us a call on +919910295915</h3>
                                <div className="thumb">
                                    <img src={signupThumb} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </>
    );
}

export default SponserHomeEight;
