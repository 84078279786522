import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import Drawer from '../Mobile/Drawer';import { Helmet } from 'react-helmet';
import DetailsService from './DetailsService';
import HeroService from './HeroService';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';import HeaderAbout from '../AboutUs/HeaderAbout';
import HeroAbout from '../AboutUs/HeroAbout';
import ServicesAbout from '../AboutUs/ServicesAbout';import SponserHomeTwo from '../HomeTwo/SponserHomeTwo';
import TeamAbout from '../AboutUs/TeamAbout';import SignupHomeEight from '../HomeEight/SignupHomeEight';
import counterIconOne from '../../assets/images/icon/counter-icon-1.svg';
function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
         <Helmet>
        <title>Siren24 | Insurance Services</title>
        <meta name="title" content="siren24 Insurance Services"/>
<meta name="description" content=" Siren24 has introduced an affordable package Ambulance Services for Insurance Companies in India. "/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <HeroAbout />
            <ServicesAbout />


            <section className="appie-service-details-area pt-50 pb-100">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="content">
                               
                        <div className="col-lg-12 col-md-12 mb-md-12">
                            <div   className='servicecontbox'                           >
                                <div>
                                <div className="icon">
                                    
                                    <i className="fas fa-bolt" />
                                </div>
                                   
                                    <p><h3 className="title">Insurance Company Services </h3> <br/> 
                           <p className='servicetxt'>        Siren24 has introduced an affordable package Ambulance Services for Insurance Companies in India.<br></br> Various features of such Corporate Services are -</p> 
<div className='servicelistdiv'>
<li>Any registered Insurance Company  can tie up with Siren24 for such ambulance services.</li>
<li>Siren24 would provide 24*7 ambulance support to the insurance holders as well as the insurance company &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;employees.</li>
<li>The services would be provided with respect to the hospitals and nursing centres mentioned in the insurance &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;holders account.</li>
<li>There would be different affordable subscription plans available at a rate less than the market rate which can be &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;chosen by the company so as to provide immediate healthcare to the insurance holders. </li>
<li>They would be provided with emergency as well as non-emergency ambulance services.</li>
<li>The Ambulances would be equipped with all necessary medical equipment and health professionals.</li>
<li>Live Location Tracking of the ambulance feature would also be available with the patient and the attendant.</li>
<li>The services are as of now available for all the registered insurance companies in Delhi, Noida, Ghaziabad, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Faridabad and Gurgaon. Though there will soon be expansion of such services in other states as well.</li>
{/* <br></br><p className='servicetxtcall'>In case of any query, give us a call on +919910295915</p> */}

</div>

                                    </p>
                                </div>
                            </div><br/>
                        </div>


        
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <TeamAbout /> */}
            {/* <SponserHomeTwo /> */}
            <SignupHomeEight />
            <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default Service;
