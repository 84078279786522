import React from 'react';
import img1 from '../fleetimg/img1.png';
import airamb from '../fleetimg/airamb.png';
import patamb from '../fleetimg/patamb.png';
import trainamb from '../fleetimg/trainamb.png';
import icuamb from '../fleetimg/icuamb.png';
import basicamb from '../fleetimg/basicamb.png';
import deadamb from '../fleetimg/deadamb.png';
import './Fleet.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const data = [
    {
        image: icuamb,
        heading: "ICU Care ambulance",
        subHeading: "Get ambulance at your doorstep",
        content: "Purpose: to transport patients who require advanced medical supervision.When to ask for these: during severe situations like - cardiac arrest, brain haemorrhage, major fractures, critical accidents etc."
    },
    {
        image: basicamb,
        heading: "Basic Life Support Ambulance",
        subHeading: "Get ambulance at your doorstep",
        content: "Purpose: to transport patients who require basic medical supervision.When to ask for these: during minor situations such as small fractures and visits to nursing homes for check-ups etc."
    },
    {
        image: patamb,
        heading: "Patient transport",
        subHeading: "Get ambulance at your doorstep",
        content: "Purpose: to transport patients who have to be transported to and from one hospital to another or from home to the hospital."
    },
    {
        image: deadamb,
        heading: "Dead body ambulance",
        subHeading: "Get ambulance at your doorstep",
        content: "Purpose: to transport the dead body of a person to the place of last rites. When to ask for these: during transport of the dead body from home to the crematorium or from hospital to the crematorium."
    },
    {
        image: airamb,
        heading: "Air ambulance",
        subHeading: "Get ambulance at your doorstep",
        content: "Purpose: to transport patients from one place to another far away place. When to ask for these: during severe situations when a patient has to be air lifted for early access to advanced medication."
    },
    {
        image: trainamb,
        heading: "Train ambulance",
        subHeading: "Get ambulance at your doorstep",
        content: "Purpose: to transport patients from one place to another far away place. When to ask for these: during severe situations when a patient has to be train lifted for early access to advanced medication."
    },
]
export const Fleet = () => {
    return (
        <>
            <h1 className='fleeth'>Meet our Awesome Ambulance Service</h1>
            {/* <div className='fleetcirculariconcont'>
                                    {
                                        data.map((item) =>
                                            <span className='fleetcircularicon'> <img src={item.image} /></span>
                                        )
                                    } 
                                </div> */}
            <Carousel
                className="custom-carousel"
                autoPlay={true}
                interval={13000}
                swipeable={true}
                infiniteLoop={true}
                showArrows={false}
                transitionTime={350}
                showStatus={false}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = { marginLeft: 10, color: "black", cursor: "pointer", fontWeight: "400", fontSize: "15px" };
                    const style = isSelected
                        ? { ...defStyle, color: "red", fontWeight: "500", textShadow: "1px 2px 3px gray" }
                        : { ...defStyle };
                    return (
                        <span
                            style={style}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            aria-label={`${label} ${index + 1}`}
                        >
                            {"o"}
                        </span>
                    );
                }}
            >

                {
                               
                                
                    data.map((item) =>
                        <div className='fleetflex-container'>   

                            <div className='flex-item-left'>
                                <img  src={item.image}  style={{marginTop:'10%'}}></img>
                            </div>
                            <div className='flex-item-right'>
                                <h1>{item.heading}</h1>
                                <h3>{item.subHeading}</h3>
                                <p>{item.content}</p>
                            </div>
                        </div>

                    )
                }
            </Carousel>

        </>
    )
}

