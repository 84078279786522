import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import { FaAmbulance } from 'react-icons/fa';

function DownloadHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-download-area pt-150 pb-160 mb-90 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="appie-download-content">
                                <span>Download Our App</span>
                                <h3 className="title">
                                    App is available <br />
                                    for free on app store
                                </h3>
                                <p>
                                Your one step solution for 24*7 Ambulance Service at your doorstep!
                                </p>
                                <ul>
                                <li >
                                        <a href="https://siren24-app.web.app/" >
                                          
                                            <FaAmbulance style={{color:'black', fontSize:'30px'}}/>
                                            <span style={{fontSize:'14px', paddingtop:'1px'}}>
                                            &nbsp;Book Now   <span style={{fontSize:'17px'}}> &nbsp;with Siren24</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="https://play.google.com/store/apps/details?id=com.yoursuperidea.siren24&hl=en_IN&gl=US">
                                            <i className="fab fa-google-play" />
                                            <span>
                                                Download for <span>Android</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-shape-1">
                    <img src={shape15} alt="" />
                </div>
                <div className="download-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="download-shape-3">
                    <img src={shape13} alt="" />
                </div>
            </section>
        </>
    );
}

export default DownloadHomeTwo;
