import React from 'react';
import { Link } from 'react-router-dom';

function Navigation({ lang = false }) {
    return (
        <>
            {lang ? (
                <ul>
                    <li>
                        <a href="#">
                            أنا <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/">الصفحة الرئيسية 1</Link>
                            </li>
                            <li>
                                <Link to="/home-two">الصفحة الرئيسية 2</Link>
                            </li>
                            <li>
                                <Link to="/home-three">المنزل 3</Link>
                            </li>
                            <li>
                                <Link to="/home-four">المنزل 4</Link>
                            </li>
                            <li>
                                <Link to="/home-five">المنزل 5</Link>
                            </li>
                            <li>
                                <Link to="/home-six">الصفحة الرئيسية 6</Link>
                            </li>
                            <li>
                                <Link to="/home-seven">المنزل 7</Link>
                            </li>
                            <li>
                                <Link to="/home-eight">الصفحة الرئيسية 8</Link>
                            </li>
                            <li>
                                <Link to="/home-dark">الصفحة الرئيسية الظلام</Link>
                            </li>
                            <li>
                                <Link to="/home-rtl">الصفحة الرئيسية Rtl</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/service">خدمة</Link>
                    </li>
                    <li>
                        <a href="#">
                            الصفحات <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/about-us">عن</Link>
                            </li>
                            <li>
                                <Link to="/error">خطأ</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            أخبار <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/news">صفحة الأخبار </Link>
                            </li>
                            <li>
                                <Link to="/news/single-news">أخبار واحدة</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/contact">اتصل</Link>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                       
                                <Link to="/home">Home</Link>
                           
                    </li>
                    {/* <li>
                        <Link to="/service">Service</Link>
                    </li> */}
                     <li>
                        {/* <a href="#">
                            Service <i className="fal fa-angle-down" />
                        </a> */}
                         <Link to="#">Services <i className="fal fa-angle-down" /></Link>
                        <ul className="sub-menu" style={{width:'300px'}}>
                        <li>
                                <Link to="/icu-care-ambulance">ICU care Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/basic-life-support-ambulance">Basic life support Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/patient-transport-ambulance">Patient transport Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/dead-body-ambulance">Dead body Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/air-ambulance">Air Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/train-ambulance">Train Ambulance</Link>
                            </li>
                        </ul>
                    </li>
              
                            <li>
                                <Link to="/about-us">About us</Link>
                            </li>
                            
                    
                       
                            <li>
                                <Link to="/news">News</Link>
                            </li>
                            
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>

                    <li>
                        
                         <Link to="#">Corporate <i className="fal fa-angle-down" /></Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/insurance-company-services">Insurance tie-up</Link>
                            </li>
                            <li>
                                <Link to="/corporate-services">Corporate tie-up</Link>
                            </li>
                           
                        </ul>
                    </li>
                </ul>
            )}
        </>
    );
}

export default Navigation;
