import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';import { Helmet } from 'react-helmet';
import SignupHomeEight from '../HomeEight/SignupHomeEight';
import TestimonialHomeEight from '../HomeEight/TestimonialHomeEight';
import WorkPartHomeEight from '../HomeEight/WorkPartHomeEight';
import FunFactHomeThree from '../HomeThree/FunFactHomeThree';
import ServicesHomeThree from '../HomeThree/ServicesHomeThree';
import Drawer from '../Mobile/Drawer';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import HeroNews from '../News/HeroNews';

import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
function AboutUsTwo() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
         <Helmet>
        <title>Siren24 | About-us</title>
        <meta name="title" content="siren24 ambulance booking app"/>
<meta name="description" content="Siren24 is a 24*7 Ambulance Booking Service platform. Our goal is to help all classes of individuals in getting on-time ambulance service at affordable prices."/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service, ambulance number"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            
            <HeroNews
                title="About Siren24"
                breadcrumb={[
                    { link: '/home', title: 'home' },
                    { link: '/about-siren24', title: 'About us' },
                ]}
                style={{ paddingBottom: '-20px' }} />
            
            <ServicesHomeThree style={{     marginTtop:'-26%' }} />
            <FunFactHomeThree /><br/>
            <WorkPartHomeEight className="pt-100" />
            <TestimonialHomeEight />
            {/* <SignupHomeEight /> */}
           {/* <div className='aboutuslistdiv'>
               
               <p className='aboutuslistdivp1'>Siren24 is a 24*7 Ambulance Booking Service platform.</p><br/>
               <p className='aboutuslistdivp2'>Provides a quick and affordable ambulance at your doorstep.</p><br/>
               <p className='aboutuslistdivp3'>Available for emergency and non-emergency health situations.</p><br/>
               <p className='aboutuslistdivp4'>Ambulances are equipped with all the required medical facilities and health professionals.</p><br/>
               <p className='aboutuslistdivp5'>Provides a range of services.</p><br/>
               <p className='aboutuslistdivp6'>Book via call or website or app with inbuilt live location tracking feature.</p><br/>

           </div> */}
            
           
           <br/> <br/> <br/>
            <FooterHomeTwo  />
            <BackToTop />
        </>
    );
}

export default AboutUsTwo;
