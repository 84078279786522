import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-2.png";
import Book from "./Book";
function Drawer({ drawer, action, lang }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("home");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${43 * getItems}px`);
      setItem(value);
    }
  };
  return (
    <>
      {lang ? (
        <>
          <div
            onClick={(e) => action(e)}
            className={`off_canvars_overlay ${drawer ? "active" : ""}`}
          ></div>
          <div className="offcanvas_menu">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div
                    className={`offcanvas_menu_wrapper ${
                      drawer ? "active" : ""
                    }`}
                  >
                    <div className="canvas_close">
                      <a href="#" onClick={(e) => action(e)}>
                        <i className="fa fa-times"></i>
                      </a>
                    </div>
                    <div className="offcanvas-brand text-center mb-40">
                      <img src={logo} alt="" />
                    </div>
                    <div id="menu" className="text-left ">
                      <ul className="offcanvas_main_menu">
                        <li
                          onClick={(e) => handler(e, "home")}
                          id="home"
                          className="menu-item-has-children active"
                        >
                          <span className="menu-expand">
                            <i className="fa fa-angle-down"></i>
                          </span>
                          <a href="#">أنا</a>
                          <ul
                            className="sub-menu"
                            style={{
                              height: item === "home" ? itemSize : "0px",
                            }}
                          >
                            <li>
                              <Link to="/">الصفحة الرئيسية 1</Link>
                            </li>
                            <li>
                              <Link to="/home-two">الصفحة الرئيسية 2</Link>
                            </li>
                            <li>
                              <Link to="/home-three">المنزل 3</Link>
                            </li>
                            <li>
                              <Link to="/home-four">المنزل 4</Link>
                            </li>
                            <li>
                              <Link to="/home-five">المنزل 5</Link>
                            </li>
                            <li>
                              <Link to="/home-six">الصفحة الرئيسية 6</Link>
                            </li>
                            <li>
                              <Link to="/home-seven">المنزل 7</Link>
                            </li>
                            <li>
                              <Link to="/home-eight">الصفحة الرئيسية 8</Link>
                            </li>
                            <li>
                              <Link to="/home-dark">
                                الصفحة الرئيسية الظلام
                              </Link>
                            </li>
                            <li>
                              <Link to="/home-rtl">الصفحة الرئيسية Rtl</Link>
                            </li>
                          </ul>
                        </li>
                        <li
                          onClick={(e) => handler(e, "service")}
                          id="service"
                          className="menu-item-has-children active"
                        >
                          <Link to="/service">خدمة</Link>
                        </li>
                        <li
                          onClick={(e) => handler(e, "pages")}
                          id="pages"
                          className="menu-item-has-children active"
                        >
                          <span className="menu-expand">
                            <i className="fa fa-angle-down"></i>
                          </span>
                          <a href="#">الصفحات</a>
                          <ul
                            className="sub-menu"
                            style={{
                              height: item === "pages" ? itemSize : "0px",
                            }}
                          >
                            <ul className="sub-menu">
                              <li>
                                <Link to="/about-us">عن</Link>
                              </li>
                              <li>
                                <Link to="/error">خطأ</Link>
                              </li>
                            </ul>
                          </ul>
                        </li>
                        <li
                          onClick={(e) => handler(e, "news")}
                          id="news"
                          className="menu-item-has-children active"
                        >
                          <span className="menu-expand">
                            <i className="fa fa-angle-down"></i>
                          </span>
                          <a href="#">أخبار</a>
                          <ul
                            className="sub-menu"
                            style={{
                              height: item === "news" ? itemSize : "0px",
                            }}
                          >
                            <ul className="sub-menu">
                              <li>
                                <Link to="/news">صفحة الأخبار</Link>
                              </li>
                              <li>
                                <Link to="/news/single-news">أخبار واحدة</Link>
                              </li>
                            </ul>
                          </ul>
                        </li>
                        <li
                          onClick={(e) => handler(e, "contact")}
                          id="contact"
                          className="menu-item-has-children active"
                        >
                          <Link to="/contact">اتصل</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="offcanvas-social">
                      <ul className="text-center">
                        <li>
                          <a href="https://www.facebook.com/pages/category/Safety---First-Aid-Service/Siren24-108257058227358/">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/Chandra56939310">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="$">
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="footer-widget-info">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fal fa-envelope"></i>
                            support@siren24.com
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fal fa-phone"></i> 342 762 44
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fal fa-map-marker-alt"></i>
                            442 Belle Terre St Floor 7, San Francisco, AV 4206
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            onClick={(e) => action(e)}
            className={`off_canvars_overlay ${drawer ? "active" : ""}`}
          ></div>
          <div className="offcanvas_menu">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div
                    className={`offcanvas_menu_wrapper ${
                      drawer ? "active" : ""
                    }`}
                  >
                    <div className="canvas_close">
                      <a href="#" onClick={(e) => action(e)}>
                        <i className="fa fa-times"></i>
                      </a>
                    </div>
                    <div className="offcanvas-brand text-center mb-40">
                      <img src={logo} alt="" />
                    </div>
                    <div id="menu" className="text-left ">
                      <ul className="offcanvas_main_menu">
                        <li>
                          <Link to="/home">Home</Link>
                        </li>

                        <li
                          onClick={(e) => handler(e, "pages1")}
                          id="pages1"
                          className="menu-item-has-children active"
                        >
                          <span className="menu-expand">
                            <i className="fa fa-angle-down"></i>
                          </span>
                          <a href="#">Service</a>
                          <ul
                            className="sub-menu"
                            style={{
                              height: item === "pages1" ? itemSize : "0px",
                            }}
                          >
                            <li>
                                <Link to="/icu-care-ambulance">ICU care Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/basic-life-support-ambulance">Basic life support Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/patient-transport-ambulance">Patient transport Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/dead-body-ambulance">Dead body Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/air-ambulance">Air Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/train-ambulance">Train Ambulance</Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link to="/about-us">About</Link>
                        </li>

                        <li>
                          <Link to="/news">News </Link>
                        </li>

                        <li
                          onClick={(e) => handler(e, "contact")}
                          id="contact"
                          className="menu-item-has-children active"
                        >
                          <Link to="/contact">Contact</Link>
                        </li>

                        <li
                          onClick={(e) => handler(e, "pages")}
                          id="pages"
                          className="menu-item-has-children active"
                        >
                          <span className="menu-expand">
                            <i className="fa fa-angle-down"></i>
                          </span>
                          <a href="#">Corporate</a>
                          <ul
                            className="sub-menu"
                            style={{
                              height: item === "pages" ? itemSize : "0px",
                            }}
                          >
                            <li>
                              <Link to="/insurance-company-services">
                                Insurance tie-up
                              </Link>
                            </li>
                            <li>
                              <Link to="/corporate-services">
                                Corporate tie-up
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>

                    <div className="offcanvas-social">
                      <ul className="text-center">
                        <li>
                          <a href="https://www.facebook.com/pages/category/Safety---First-Aid-Service/Siren24-108257058227358/">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/Chandra56939310">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        {/* <li>
                                                    <a href="$">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                </li> */}
                        <li>
                          <a href="https://www.linkedin.com/in/siren-ambulance-413a7a219/?originalSubdomain=in">
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="footer-widget-info">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fal fa-envelope"></i>
                            support@siren24.in
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fal fa-phone"></i> +919910295915
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fal fa-map-marker-alt"></i>
                            Defence colony, New Delhi-110024
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Book />
        </>
      )}
    </>
  );
}

export default Drawer;
