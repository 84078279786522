import React from 'react'

export default function Book() {
  return (
    <>
                                        {/* <a className="main-btn ml-30" href="/#" style={{position:'', top:'200px', left:'120px'}}>
                                        Book Now!
                                    </a> */}
    </>
  )
}
