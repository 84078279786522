import React from "react";
import thumb from "../../assets/images/service-details-thumb.jpg";

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30 Employees
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Appie Template
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>quomodosoft.com/appie
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div> */}
            <div className="col-lg-12">
              <div className="service-details-content">
                <div className="thumb">
                  {/* <img src={thumb} alt="" /> */}
                </div>
                <div className="content">
                  <h3 className="title">Privacy Policy</h3>
                  <p>
                    This page is used to inform visitors regarding our policies
                    with the collection, use, and disclosure of Personal
                    Information if anyone decided to use any of our Service.If
                    you choose to use our Service, then you agree to the
                    collection and use of information in relation to this
                    policy. The Personal Information that we collect is used for
                    providing and improving the Service. We will not use or
                    share your information with anyone except as described in
                    this Privacy Policy.The terms used in this Privacy Policy
                    have the same meanings as in our Terms and Conditions, which
                    is accessible at our all Apps unless otherwise defined in
                    this Privacy Policy.
                  </p>
                  <span>INFORMATION COLLECTION AND USE</span>
                  <p>
                    For a better experience, while using our Service, we will
                    require you to provide us with certain personally
                    identifiable information, including but not limited to 
                    <li>First Name</li>
                    <li> Last Name</li>
                    <li> Email Address</li>
                    <li> Phone Number</li><br/> The information
                    that we request will be retained by us and used as described
                    in this privacy policy. We do collect the following
                    sensitive information when you use or Sign up on our App We
                    collect your location data to enable 
                    <li>Search Vehicle location</li>
                    <li>Travel Route and Navigation</li>
                    <li>Real-time Vehicle movement</li> <br/>The
                    app does use third party services that may collect
                    information used to identify you. Link to privacy policy of
                    third party service providers used by the app
                  </p>
                  <span>LOG DATA</span>
                  <p>
                    We want to inform you that whenever you use our Service, in
                    a case of an error in the app we collect data and
                    information (through third party products) on your phone
                    called Log Data. This Log Data may include information such
                    as your device Internet Protocol (“IP”) address, device
                    name, operating system version, the configuration of the app
                    when utilizing our Service, the time and date of your use of
                    the Service, and other statistics.
                  </p>
                  <span>COOKIES</span>
                  <p>
                    Cookies are files with a small amount of data that are
                    commonly used as anonymous unique identifiers. These are
                    sent to your browser from the websites that you visit and
                    are stored on your device's internal memory. This Service
                    does not use these “cookies” explicitly. However, the app
                    may use third party code and libraries that use “cookies” to
                    collect information and improve their services. You have the
                    option to either accept or refuse these cookies and know
                    when a cookie is being sent to your device. If you choose to
                    refuse our cookies, you may not be able to use some portions
                    of this Service.
                  </p>
                  <span>SERVICE PROVIDERS</span>
                  <p>
                    We may employ third-party companies and individuals due to
                    the following reasons:
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; </li>
                    <li>To assist us in analyzing how our Service is used.</li>
                    We want to inform users of this Service that these third
                    parties have access to your Personal Information. The reason
                    is to perform the tasks assigned to them on our behalf.
                    However, they are obligated not to disclose or use the
                    information for any other purpose.
                  </p>
                  <span>SECURITY</span>
                  <p>
                    We value your trust in providing us your Personal
                    Information, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no
                    method of transmission over the internet, or method of
                    electronic storage is 100% secure and reliable, and we
                    cannot guarantee its absolute security.
                  </p>
                  <span>LINKS TO OTHER SITES</span>
                  <p>
                    This Service may contain links to other sites. If you click
                    on a third-party link, you will be directed to that site.
                    Note that these external sites are not operated by us.
                    Therefore, we strongly advise you to review the Privacy
                    Policy of these websites. We have no control over and assume
                    no responsibility for the content, privacy policies, or
                    practices of any third-party sites or services.
                  </p>
                  <span>CHILDREN’S PRIVACY</span>
                  <p>
                    These Services do not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from children under 13. In the case we discover that a child
                    under 13 has provided us with personal information, we
                    immediately delete this from our servers. If you are a
                    parent or guardian and you are aware that your child has
                    provided us with personal information, please contact us so
                    that we will be able to do necessary actions.
                  </p>
                  <span>DELETE USER DATA</span>
                  <p>
                    User can remove all their data from the system by deleting
                    the account from profile page in mobile app. Once you delete
                    your account:
                    <li>
                      All your data will be purged from the system. Your profile
                      image, email, phone number, all booking history,
                      everything will be permanently removed.
                    </li>
                    <li>Deleted user data and account are irrecoverable.</li>
                  </p>
                  <span>CHANGES TO THIS PRIVACY POLICY</span>
                  <p>
                    We may update our Privacy Policy from time to time. Thus,
                    you are advised to review this page periodically for any
                    changes. We will notify you of any changes by posting the
                    new Privacy Policy on this page.
                    <br />
                    This policy is effective as of 2020-12-21
                  </p>
                  <span>CONTACT US</span>
                  <p>
                    If you have any questions or suggestions about our Privacy
                    Policy, do not hesitate to contact us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
