import React from "react";
import thumb from "../../assets/images/service-details-thumb.jpg";

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30 Employees
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Appie Template
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>quomodosoft.com/appie
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div> */}
            <div className="col-lg-12">
              <div className="service-details-content">
                <div className="thumb">
                  {/* <img src={thumb} alt="" /> */}
                </div>
                <div className="content">
                  <h3 className="title">Terms and Conditions</h3>
                  <p>
                    The Siren24 under tree Apple Healthcare private limited is
                    an aggregator of Third Party health-related Services, and
                    all information, content, materials, products (including
                    software), and other services included on or otherwise made
                    available to you through the Siren24.in website / Siren24
                    Mobile App / Offline channels / Helpline numbers hereby
                    referred to as CHANNEL and by any other means through
                    Siren24 representatives are provided by Siren24 on a best
                    effort and “as “and “as available” basis unless otherwise
                    specified in writing, Siren24 makes no representations or
                    warranties of any kind, express or implied, as to the
                    operation of siren24 services, or the information, content,
                    materials, products (including Software) or other services
                    included (As IS Where IS Basis).
                  </p>
                  <span>Non-Performance of Contract:</span>
                  <p>You accept and acknowledge the following: </p>

                  <p>
                    1. Siren24 is not responsible for any unsatisfactory,
                    delayed, non-performance, or breach of the contract entered
                    into between You and the Third Party Partner OR Siren24 as a
                    representative of the Third Party for purchase and sale of
                    goods or services offered by such Third Party Partner on the
                    CHANNEL.
                    <br />
                    <br />
                    2 Siren24 cannot and does not guarantee that the concerned
                    Third Party Partner will perform any transaction concluded
                    on the CHANNEL.
                    <br />
                    <br />
                    3. The Third-Party Operators – Ambulance(s) / Medical
                    Service Providers are solely responsible for ensuring that
                    the Goods and Services offered for use/procurement through
                    the CHANNEL are kept in availability for successful
                    fulfillment of service requests received. Consequently,
                    Siren24 is not responsible if the Third-Party Operator(s)
                    does not satisfy the contract for services that are out of
                    availability, delay, or otherwise unavailable, but were
                    shown as available on the CHANNEL at the time of placement
                    of service requirement request by You.
                    <br />
                    <br />
                    4 Siren 24 shall not and is not required to mediate or
                    resolve any dispute or disagreement between caused to the
                    User including loss of life as a result of delay or default
                    or deficiency or failure in the Services offered by Third
                    Party Operators You and Third Party Ambulance
                    Agencies/Drivers / Operator. In particular, siren24 does not
                    implicitly or explicitly guarantee the services on the
                    CHANNEL.
                    <br />
                    <br />
                    5. Other Parties accept and acknowledge that Siren24 shall
                    not be liable for any loss or damage caused to the User
                    including loss of life as a result of delay or default or
                    deficiency or failure in the Services offered by Third Party
                    Operators.
                    <br />
                    <br />
                    6. Siren24 doesn't give any warranty that the website is
                    virus-free. It is a known thing that no one can maintain a
                    website virus-free completely.
                    <br />
                    <br />
                    7. In no event shall siren24 be liable for, or in connection
                    with, (i) the provision of, or failure to provide, all or
                    any products or service by a Third Party Service Provider to
                    any User; or (ii) any comments or feedback given by any of
                    the Users in relation to the goods or services provided by
                    any Third Party Service Providers; or (iii) any content
                    posted, transmitted, exchanged or received by or on behalf
                    of any User, Third Party Service Providers or another person
                    on or through the CHANNEL or any Social Media Platform.
                    <br />
                    <br />
                    8. The Website may be linked to the website of third
                    parties, affiliates, and business partners. siren24 has no
                    control over, and is not liable or responsible for content,
                    accuracy, validity, reliability, quality, security of such
                    websites or made available by/through the Website. Inclusion
                    of any link on the Website does not imply that siren24
                    endorses the linked website. Other Parties may use the links
                    and these services at their own risk.
                    <br />
                    <br />
                    9. Other Parties accept and acknowledge that Siren24 shall
                    not be liable for any loss or damage caused to the User
                    including loss of life as a result of delay or default or
                    deficiency or failure in the Services as a result of any
                    natural disasters, fire, riots, civil disturbances, actions
                    or decrees of governmental bodies, communication line
                    failures (which are not caused due to the fault of Siren24
                    or the Third Party Service Providers), or any other delay or
                    default or deficiency or failure which arises from causes
                    beyond Siren24 reasonable control (“Force Majeure Event”).
                    In the event of any Force Majeure Event arising, siren24
                    depending on whose performance has been impacted under the
                    Terms of Use, shall immediately give notice to the Other
                    Party(s) of the facts which constitute the Force Majeure
                    Event.
                    <br />
                    <br />
                    10. Governing Law and Dispute Resolution: These Terms of Use
                    and any contractual obligation between the Parties will be
                    governed by the laws of Other Party(s) access to, or use of,
                    the CHANNEL or these Terms of Use shall be subject to the
                    exclusive jurisdiction of the courts at Delhi. All disputes
                    will be subject to arbitration at Delhi in English by a sole
                    arbitrator appointed by tree Apple Healthcare private
                    limited under the Arbitration and Conciliation Act, 1996.
                    <br />
                    <br />
                    11. Contact Information: If any Other Party(s) has any
                    grievance, comment, question, or suggestion regarding any of
                    our Services, please contact our customer service at
                    contact@siren24.in If any Other Party(s) has any questions
                    concerning Siren24, the Website, these Terms of Use, or
                    anything related to any of the foregoing, siren24 can be
                    reached at the following email address contact@siren24.in.
                    <br />
                    <br />
                    12. Limitation Liability: UNDER NO CIRCUMSTANCES SHALL
                    COMPANY OR ITS AFFILIATES, PARTNERS, SUPPLIERS OR LICENSORS
                    BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                    SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN
                    CONNECTION WITH YOUR ACCESS OR USE OF OR INABILITY TO ACCESS
                    OR USE THE APPLICATION AND ANY THIRD PARTY CONTENT AND
                    SERVICES, WHETHER OR NOT THE DAMAGES WERE FORESEEABLE AND
                    WHETHER OR NOT COMPANY WAS ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES, WITHOUT LIMITING THE GENERALITY OF THE
                    FOREGOING, COMPANY'S AGGREGATE LIABILITY TO YOU (WHETHER
                    UNDER CONTRACT, TORT, STATUTE OR OTHERWISE), SHALL NOT
                    EXCEED THE AMOUNT OF FIVE HUNDRED RUPEES (Rs.500/-). THE
                    FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE-STATED
                    REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
