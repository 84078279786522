import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                <h2 className="title">Siren24 Services</h2>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-content">
                                <h3 className="title">
                                Various types of Ambulances
                                </h3>
                                <p style={{fontSize: '22px'}}>
                                Emergency and Non-Emergency like - ICU Ambulance, Mortuary Ambulance, Basic Ambulance, etc.
                                </p><br/><br/><br/>
                                <h3 className="title">
                                Health Professionals on Call
                                </h3>
                                <p style={{fontSize: '23px'}}>
                                
to guide, advise and monitor the patient’s health while in the ambulance
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
