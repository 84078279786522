import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import testmonialUser from '../../assets/images/testimonial-user.png';

function TestimonialHomeTwo() {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: false,
    };
    return (
        <>
            <section className="appie-testimonial-2-area mb-90" id="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-testimonial-2-box">
                                <div
                                    className="appie-testimonial-slider-2"
                                    style={{ position: 'relative' }}
                                >
                                    <span
                                        onClick={sliderPrev}
                                        className="prev slick-arrow"
                                        style={{ display: 'block' }}
                                    >
                                        <i className="fal fa-arrow-left" />
                                    </span>
                                    <Slider ref={sliderRef} {...settings}>
                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.6) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    My husband had sudden chest pain and I immediately contacted Siren24 for an ambulance. Within a few minutes, the ambulance was at my doorstep. Very quick response from the team.
                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Janhavi Birde</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.8) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    I have seen many ambulance services in Delhi, but no one was as helpful, affordable and quick as Siren24. Highly recommended, if you are in need of quick ambulance support at your doorstep.   
                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Nisha Jain</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.9) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    My neighbour suffered from an accident. I had seen the Siren24 ad and contacted them for an ambulance. The ambulance response was so quick and the staff was very helpful. Thank you so much for such an amazing response.
                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Anish Kachaliya</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.8) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    I contacted Siren24 for an urgent ambulance at my place at 2 AM in the night, and they were so quick and responsive to my request. They truly provide 24*7 ambulance service. Thank you for your exceptional service.    
                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Disha Pacholkar</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(5.0) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    My grandfather had to get a health check up done from the hospital and we wanted an ambulance for the trip. Siren24 was of such great help as they provided all the necessary medical aid to my grandfather and a comfortable and safe travel. Thank you so much Siren24! 
                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Anaya Jalala</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.7) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    Siren24 ambulances are very quick and affordable. They offer a variety of ambulances - you can also choose whichever one is the most suitable for your patient.     
                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Rucha Deshmukh</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                    <span
                                        onClick={sliderNext}
                                        className="next slick-arrow"
                                        style={{ display: 'block' }}
                                    >
                                        <i className="fal fa-arrow-right" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TestimonialHomeTwo;
