import React from 'react';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import BackToTop from '../BackToTop';import ShareIcons from './ShareIcons';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import cmnt1 from '../../assets/images/single-post/c1.png';
import cmnt2 from '../../assets/images/single-post/c2.png';
import cmnt3 from '../../assets/images/single-post/c3.png';
import HeroNews from './HeroNews';import { Helmet } from 'react-helmet';
function Blog() {
    return (
        <> 
                       <Helmet>
        <title>Siren24 | How to book an Ambulance Service in Ghaziabad at Siren24? </title>
        <meta name="title" content="How to book an Ambulance Service in Ghaziabad at Siren24?"/>
<meta name="description" content="Book any type of ambulance at Ghaziabad, that is needed as per your requirment and patient's condition, with Siren24. "/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service, ambulance number, ambulance news,ambulance service at Ghaziabad"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
        <HeaderHomeTwo />
        <HeroNews
                title="How to book an Ambulance Service in Ghaziabad at Siren24?"
                breadcrumb={[
                    { link: '/home', title: 'home' },
                    { link: '/news', title: 'News' },
                ]}
            /><br/><br/><br/>
            <div className="container">
                <p>
                Ghaziabad - a city of Uttar Pradesh and a part of Delhi-NCR, has an approximate population of 18 lakh. It is majorly connected by roads and railways for commuting, making it a rail junction for North India. Recent construction works have led to the city being described by a City Mayors Foundation survey as the second fastest-growing city in the world. The development of Ghaziabad has been done in various social sectors as well like education, transportation, health, livelihood, etc. To contribute to this, Siren24 has come up with an initiative of 24*7 Ambulance Service in Ghaziabad, which is faster and affordable.

Though being the largest city in Western UP, there is still a gap which needs to be filled when it comes to health infrastructure. There are various hospitals in the area, like - Max Super Specialty Hospital, Aarogya Hospital, Healing Tree Hospital, Columbia Asia Hospital, etc. During the pandemic, there was a huge rush of patients in Delhi and Delhi-NCR, but there was no immediate help available due to a lack of resources. There was the unavailability of fast-equipped ambulances, oxygen support, ICU beds, Ventilators, etc. This caused a lot more lives to be lost than anticipated. Such visuals took a toll on not only the nationals but also everyone around the globe. 


                </p><br/><br/>
                {/* <div className="post-thumb">
                    <img src={singlePost} alt="" />
                </div> */}
                <h4 className="article-title">How to book an Ambulance Service in Faridabad at Siren24?</h4>
                <p>
                Siren24 has various types of ambulances to choose from - Basic/Non-emergency ambulance, ICU/Ventilator Ambulance, Train/Air Ambulance, etc. The procedure to book an ambulance is hassle-free and can be done in any of the 3 ways -
1.	Via call - +919910295915
2.	Via Siren24 app, which is available on Android and iOS both and is free to download. After installing the app, you have to register with your contact number, then put in the pickup and drop-off location.
3.	Via Siren24 website 

The app and the website are also equipped with live location tracking.

The patient can be taken from home to hospital or from one hospital to another hospital. Post-treatment patients who require basic support during regular checkups can also be helped through our ambulances. 

Trust us with your loved ones getting the treatment on time!



                </p>
            
                {/* <blockquote>
                    <p>
                        I don't want no agro brilliant are you taking the piss skive off super boot
                        chancer don't get shirty.
                    </p>
                    <cite>Indigo Violet</cite>
                </blockquote> */}

                
               <ShareIcons/>
                <br/><br/>
            </div>
            
            <div className="comment-form-area container">
                <h3>Post a Comment</h3>
                <p>Your email address will not be published. Required fields are marked</p>
                <div className="comment-form">
                    <form className="row" action="#" method="post">
                        <div className="col-md-6">
                            <input type="text" name="name" placeholder="Name" />
                        </div>
                        <div className="col-md-6">
                            <input type="email" name="email" placeholder="Email" />
                        </div>
                        <div className="col-md-12">
                            <input type="url" name="url" placeholder="Website" />
                        </div>
                        <div className="col-md-12">
                            <div className="save-comment">
                                <input id="history-name" type="checkbox" />
                                <label htmlFor="history-name">
                                    Save my name, email, and website in this browser for the next
                                    time I comment.
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <textarea placeholder="Coment"></textarea>
                        </div>
                        <div className="col-md-12">
                            <button type="submit">Post Comment</button>
                        </div>
                    </form><br/><br/><br/>
                </div>
            </div>  <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default Blog;
