import React from 'react';
import deadamb from '../fleetimg/deadamb.png';
import useToggle from '../../Hooks/useToggle';import { Helmet } from 'react-helmet';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import FaqHome from '../HomeTwo/FaqHome';import { FaAmbulance } from 'react-icons/fa';
import { Fleet } from '../HomeTwo/Fleet';
import ServicesHomeTwo from '../HomeTwo/ServicesHomeTwo';
import '../HomeTwo/Fleet.css';
import Allambstabs from './Allambstabs';

export default function Air() {
    const [drawer, drawerAction] = useToggle(false);
  return (
    <>
    <Helmet>
<title>Siren24 | Dead body Ambulance</title>
<meta name="title" content="Dead body ambulance booking app"/>
<meta name="description" content="Generally, a mortuary ambulance is preferred for transporting a deceased person."/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service,dead body ambulance"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
</Helmet>

       <Drawer drawer={drawer} action={drawerAction.toggle} />
   <HeaderHomeTwo action={drawerAction.toggle} /><br/><br/><br/><br/><br/>

   <div className="fleetflex-container">
<div className="flex-item-right">
 <h1>Dead body ambulance</h1><br/>
 <h3>Get ambulance at your doorstep</h3>
 <p style={{fontSize:'16px',paddingTop:'5px',marginLeft:'25px'}}>Purpose: to transport the dead body of a person to the place of last rites. When to ask for these: during transport of the dead body from home to the crematorium or from hospital to the crematorium.</p>
 <div className='appie-hero-content appie-download-content'>
                       <ul >
                       <li >
                                        <a href="https://siren24-app.web.app/" >
                                          
                                            <FaAmbulance style={{color:'black', fontSize:'30px'}}/>
                                            <span style={{fontSize:'20px', paddingtop:'1px'}}>
                                            &nbsp;Book Now   <span> Ambulace</span>
                                            </span>
                                        </a>
                                    </li>
                           <li>
                               <a className="item-2" href="https://play.google.com/store/apps/details?id=com.yoursuperidea.siren24&hl=en_IN&gl=US">
                                   <i className="fab fa-google-play" />
                                   <span>
                                       Download for <span>Android</span>
                                   </span>
                               </a>
                           </li>
                       </ul>
                       
                       </div>
</div>
<div >
 <img src={deadamb} style={{ width:'280px'}} className='indimgserv'></img>
</div>
</div><br/><br/>

   <ServicesHomeTwo />
   <Allambstabs/>
      <FaqHome/>
   <FooterHomeTwo />
   <BackToTop className="back-to-top-2" />
</>
  )
}
