import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import user from '../../assets/images/testimonial-user-1.png';

function TestimonialHomeEight() {
    const articleCarosel = useRef();
    const peopleCarosel = useRef();
    const sliderNext = () => {
        articleCarosel.current.slickNext();
        peopleCarosel.current.slickNext();
    };
    const sliderPrev = () => {
        articleCarosel.current.slickPrev();
        peopleCarosel.current.slickPrev();
    };
    const settingsForArticle = {
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const settingsForPeople = {
        centerMode: true,
        centerPadding: '0px',
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
    };
    return (
        <>
            <div className="appie-testimonial-about-area pb-45">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">What clients say about us</h3>
                                <p>The app provides various types of ambulances.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div
                                className="testimonial-about-slider-active"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    onClick={sliderPrev}
                                    className="prev slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-left"></i>
                                </span>
                                <Slider {...settingsForArticle} ref={articleCarosel}>
                                    <div className="testimonial-parent-item">
                                        <div className="testimonial-box">
                                            <div className="icon">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <div className="ratings-icon">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                

                                            My husband had sudden chest pain and I immediately contacted Siren24 for an ambulance. Within a few minutes, the ambulance was at my doorstep. Very quick response from the team.

                                            </p>
                                        </div>
                                    </div>
                                    <div className="testimonial-parent-item">
                                        <div className="testimonial-box">
                                            <div className="icon">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <div className="ratings-icon">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                            My neighbour suffered from an accident. I had seen the Siren24 ad and contacted them for an ambulance. The ambulance response was so quick and the staff was very helpful. Thank you so much for such an amazing response.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="testimonial-parent-item">
                                        <div className="testimonial-box">
                                            <div className="icon">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <div className="ratings-icon">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                            I have seen many ambulance services in Delhi, but no one was as helpful, affordable and quick as Siren24. Highly recommended, if you are in need of quick ambulance support at your doorstep.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="testimonial-parent-item">
                                        <div className="testimonial-box">
                                            <div className="icon">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <div className="ratings-icon">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                            I contacted Siren24 for an urgent ambulance at my place at 2 AM in the night, and they were so quick and responsive to my request. They truly provide 24*7 ambulance service. Thank you for your exceptional service.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="testimonial-parent-item">
                                        <div className="testimonial-box">
                                            <div className="icon">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <div className="ratings-icon">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                            My grandfather had to get a health check up done from the hospital and we wanted an ambulance for the trip. Siren24 was of such great help as they provided all the necessary medical aid to my grandfather and a comfortable and safe travel. Thank you so much Siren24!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="testimonial-parent-item">
                                        <div className="testimonial-box">
                                            <div className="icon">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <div className="ratings-icon">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                            Siren24 ambulances are very quick and affordable. They offer a variety of ambulances - you can also choose whichever one is the most suitable for your patient.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                                <span
                                    onClick={sliderNext}
                                    className="next slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="testimonial-box-about-slider-small-active">
                                <Slider {...settingsForPeople} ref={peopleCarosel}>
                                    <div className="item">
                                        <div className="thumb">
                                            <img src={user} alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="title">Bodrum Salvador</h5>
                                            <span>Product Designer</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="thumb">
                                            <img src={user} alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="title">Bodrum Salvador</h5>
                                            <span>Product Designer</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="thumb">
                                            <img src={user} alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="title">Bodrum Salvador</h5>
                                            <span>Product Designer</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="thumb">
                                            <img src={user} alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="title">Bodrum Salvador</h5>
                                            <span>Product Designer</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="thumb">
                                            <img src={user} alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="title">Bodrum Salvador</h5>
                                            <span>Product Designer</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="thumb">
                                            <img src={user} alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="title">Bodrum Salvador</h5>
                                            <span>Product Designer</span>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TestimonialHomeEight;
