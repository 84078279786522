import React from 'react';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import BackToTop from '../BackToTop';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import S24drivertopbg from './S24driverimg/S24drivertopbg.png';
import driver1 from './S24driverimg/driver1.png';
import './S24driver.css';
export default function S24driver() {
    return (
        <><HeaderHomeTwo /><br/><br/><br/><br/>
            <div className='S24drivertopbgdiv1'>
                <img src={S24drivertopbg} />
                <div className='S24drivertopbgtext'><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p></div>
            </div>

            <div className='S24drivertopbgdiv2'>
                <h1>Make Money. Earn Respect. Secure Your Future.</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
            </div>

            <div className='drivercardcont'>
                <div className='drivercard'>
                    <img className='drivercardimg' src={driver1} />
                    <h1>Join Siren24 as a</h1>
                    <h2>Partner with an ambulance</h2>
                    <button>Attach my Ambulance</button>
                </div>
                <div className='drivercard'>
                    <img className='drivercardimg' src={driver1} />
                    <h1>Know driving?</h1>
                    <h2>We've an ambulance for you!</h2>
                    <button>Need an Ambulance</button>
                </div>
                <div className='drivercard'>
                    <img className='drivercardimg' src={driver1} />
                    <h1>Become a</h1>
                    <h2>Fleet operator</h2>
                    <button>Attach my Ambulance</button>
                </div>
            </div>

            <div className='S24driverldiv'>
                 <div className='S24driverldiv1'>
                    <h1>Attach your Ambulance with Siren24</h1>
                    <p>Know about more ways of partnering with Ola and how each category can help you earn more.</p>
                </div>

                <div className='S24driverldiv2'>
                    <button>Send Enquiry</button>
                </div>
                </div><br/><br/><br/>
                <FooterHomeTwo />
            <BackToTop />
        </>
    )
}
