import React from 'react';
import useToggle from '../../Hooks/useToggle';import { Helmet } from 'react-helmet';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import CounterArea from './CounterArea';
import DownloadHomeTwo from './DownloadHomeTwo';
import FeaturesHomeTwo from './FeaturesHomeTwo';
import FooterHomeTwo from './FooterHomeTwo';
import HeaderHomeTwo from './HeaderHomeTwo';
import HeroHomeTwo from './HeroHomeTwo';
import ServicesHomeTwo from './ServicesHomeTwo';
import TestimonialHomeTwo from './TestimonialHomeTwo';

import FaqHome from './FaqHome';
import { Fleet } from './Fleet';
import WorkPartHomeEight from '../HomeEight/WorkPartHomeEight';
function HomeTwo() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
         <Helmet>
        <title>Siren24 | Home</title>
        <meta name="title" content="siren24 ambulance booking app"/>
<meta name="description" content="Ambulance booking now in just 60 seconds Track the live location of your ambulance. Book without burning a hole in your pocket."/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <HeroHomeTwo />
            <ServicesHomeTwo />
            {/* <AboutHomeTwo /> */}
            <FeaturesHomeTwo />
            <CounterArea />
            <Fleet/>
            {/* <VideoPlayerHomeTwo /> */}
            <DownloadHomeTwo />
            <WorkPartHomeEight className="pt-100" />
            <FaqHome/>
            {/* <PricingHomeTwo /> */}
            <TestimonialHomeTwo />
            {/* <SponserHomeTwo /> */}
            <FooterHomeTwo />
            <BackToTop className="back-to-top-2" />
        </>
    );
}

export default HomeTwo;
