import React from "react";
import thumb from "../../assets/images/service-details-thumb.jpg";

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30 Employees
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Appie Template
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>quomodosoft.com/appie
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div> */}
            <div className="col-lg-12">
              <div className="service-details-content">
                {/* <div className="thumb">
                  <img src={thumb} alt="" />
                </div> */}
                <div className="content">
                  <h3 className="title">Cancellation Policy</h3>
                  <p style={{fontSize:"20px"}}>
                    Cancellation by the User who booked the Ambulance –
                    Cancellation in case the ambulance has not yet moved towards
                    the pickup truck point or up to 5 minutes of booking time,
                    no charge from User. Cancellation in case the ambulance
                    started moving towards the pickup truck item, 50% of the
                    estimated cost is paid by the User Cancellation after the
                    arrival of the ambulance, 100% the estimated tariff is paid
                    by the User In case of cancellation and payment in cash
                    Driver must take measures to receive funds from the User who
                    canceled trip and money must be in accordance with the
                    cancellation policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
