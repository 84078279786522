import React from 'react';
import './AttachFleet.css';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import BackToTop from '../BackToTop';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import AttachFleetformbg from './AttachFleetimg/AttachFleetformbg.png'
import Vector from './AttachFleetimg/Vector.png';
import AttachFleetd1 from './AttachFleetimg/AttachFleetd1.png';
import AttachFleetd2 from './AttachFleetimg/AttachFleetd2.png';
import AttachFleetd3 from './AttachFleetimg/AttachFleetd3.png';
import AttachFleetd4 from './AttachFleetimg/AttachFleetd4.png';import { Helmet } from 'react-helmet';
export default function AttachFleet() {
    return (
        <>
        <Helmet>
        <title>Siren24 | Become a driver</title>
        <meta name="title" content=" Become a driver at siren24"/>
<meta name="description" content="Submit your application and run your Fleet on Siren24. "/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
        <HeaderHomeTwo /><br/><br/><br/><br/>
            <div className='AttachFleetdiv1'>
                <img className='AttachFleetformbg' src={AttachFleetformbg} />
                <h1 className='AttachFleetformbgh1'>Become a Partner </h1>
                <form className='AttachFleetform'>
                    <h1>Run your Fleet on Siren24</h1>
                    <p>Enter your details below. Our team will contact you in the next 24 hours.</p>
                    <input type="text" placeholder="Enter your name"></input><br /><br />
                    <input type="text" placeholder="Enter your Phone number"></input><br /><br />
                    <input type="text" placeholder="Enter City"></input><br /><br />
                    <button>Submit Your Application</button>
                </form>
            </div>

            <div className='AttachFleetdiv2'><br /><br />
                <h1 className='AttachFleetdivh1redb'>  Track and control <br />  your fleet of cars with one app</h1>


                <div className='taccont1'>
                    <div className='taccontdiv'>
                        <div className='VectorCircle'><img src={Vector} /></div>
                        <p>Live tracking of all your drivers and cars</p>
                    </div>
                    <div className='taccontdiv'>
                        <div className='VectorCircle'><img src={Vector} /></div>
                        <p>Get detailed reports of total earnings</p>
                    </div>
                    <div className='taccontdiv'>
                        <div className='VectorCircle'><img src={Vector} /></div>
                        <p>Get detailed reports of total earnings</p>
                    </div>
                </div>
                <div className='taccont2'>
                    <div className='taccontdiv'>
                        <div className='VectorCircle'><img src={Vector} /></div>
                        <p>Live tracking of all your drivers and cars</p>
                    </div>
                    <div className='taccontdiv'>
                        <div className='VectorCircle'><img src={Vector} /></div>
                        <p>Get detailed reports of total earnings</p>
                    </div>
                </div>
            </div>

            <div className='AttachFleetdiv3'>
                <br /><br /><br />
                <h1 className='AttachFleetdivh1redb'> Document Required<br />to start driving with siren24</h1>
                <p>More documents may be required based on your city</p>

                <div className='AttachFleetdiv3cards'>
                    <div className='AttachFleetdiv3card'>
                        <h1>Owner Documents</h1>
                        <ol>
                            <li>PAN Card</li>
                            <li>Cancelled Cheque</li>
                            <li>Aadhaar Card</li>
                            <li>Address Proof</li>
                        </ol>
                    </div>
                    <div className='AttachFleetdiv3card'>
                        <h1>Ambulance Documents</h1>
                        <ol>
                            <li>Vehicle RC</li>
                            <li>Vehicle Permit</li>
                            <li>Vehicle Insurance</li>
                        </ol>
                    </div>
                    <div className='AttachFleetdiv3card'>
                        <h1>Driver Documents</h1>
                        <ol>
                            <li>Driving License</li>
                            <li>Aadhaar Card</li>
                            <li>Address Proof</li>
                        </ol>
                    </div>
                </div>
            </div>

            {/* <div className='AttachFleetdiv4'>
                <br /><br /><br />
                <h1 className='AttachFleetdivh1redb'> Hear it from our driver-partners <br />what they love about us</h1>

                <div className='AttachFleetdiv4cont'>
                    <div className='AttachFleetdiv4card'>
                        <div className='AttachFleetdiv4cardimg'><img src={ AttachFleetd1} /></div>
                        <div className='AttachFleetdiv4cardtext'>
                            <p>"Joining Siren24 as an Operator has been one of my best business decisions. Technical support from Ola has just been amazing!"</p>
                            <h4>Swayam Prabha Travels, Bengaluru</h4>
                        </div>
                    </div>
                    <div className='AttachFleetdiv4card'>
                        <div className='AttachFleetdiv4cardimg'><img src={AttachFleetd2 } /></div>
                        <div className='AttachFleetdiv4cardtext'>
                            <p>"Joining Siren24 as an Operator has been one of my best business decisions. Technical support from Ola has just been amazing!"</p>
                            <h4>Swayam Prabha Travels, Bengaluru</h4>
                        </div>
                    </div>
                </div>

                <div className='AttachFleetdiv4cont'>
                    <div className='AttachFleetdiv4card'>
                        <div className='AttachFleetdiv4cardimg'><img src={ AttachFleetd3} /></div>
                        <div className='AttachFleetdiv4cardtext'>
                            <p>"Joining Siren24 as an Operator has been one of my best business decisions. Technical support from Ola has just been amazing!"</p>
                            <h4>Swayam Prabha Travels, Bengaluru</h4>
                        </div>
                    </div>
                    <div className='AttachFleetdiv4card'>
                        <div className='AttachFleetdiv4cardimg'><img src={AttachFleetd4 } /></div>
                        <div className='AttachFleetdiv4cardtext'>
                            <p>"Joining Siren24 as an Operator has been one of my best business decisions. Technical support from Ola has just been amazing!"</p>
                            <h4>Swayam Prabha Travels, Bengaluru</h4>
                        </div>
                    </div>
                </div>

            </div> */}

            {/* <div className='AttachFleetdiv5'>
            <br /><br /><br />
                <h1 className='AttachFleetdivh1redb'>  Find answers<br />to all your important questions</h1>
            </div><br/><br/><br/> */}
            <FooterHomeTwo />
            <BackToTop />
        </>
    )
}
