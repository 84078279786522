import React from 'react';
import trainamb from '../fleetimg/trainamb.png';
import useToggle from '../../Hooks/useToggle';import { Helmet } from 'react-helmet';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import FaqHome from '../HomeTwo/FaqHome';
import { Fleet } from '../HomeTwo/Fleet';
import ServicesHomeTwo from '../HomeTwo/ServicesHomeTwo';
import '../HomeTwo/Fleet.css';import { FaAmbulance } from 'react-icons/fa';
import Allambstabs from './Allambstabs';


export default function Air() {
    const [drawer, drawerAction] = useToggle(false);
  return (
    <>
    <Helmet>
<title>Siren24 | Train Ambulance</title>
<meta name="title" content="Train ambulance booking app"/>
<meta name="description" content="Train Ambulance is the most reliable and Low cost medium to transfer a patient who is too ill to avail the ground ambulance.Book ambulance now online with siren24"/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service,train ambulance"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
</Helmet>

       <Drawer drawer={drawer} action={drawerAction.toggle} />
   <HeaderHomeTwo action={drawerAction.toggle} /><br/><br/><br/><br/><br/>

   <div className="fleetflex-container">
<div className="flex-item-right">
 <h1>Train Ambulance</h1><br/>
 <h3>Get ambulance at your doorstep</h3>
 <p style={{fontSize:'16px',paddingTop:'5px',marginLeft:'25px'}}>Purpose: to transport patients from one place to another far away place. When to ask for these: during severe situations when a patient has to be train lifted for early access to advanced medication.</p>
 <div className='appie-hero-content appie-download-content'>
                       <ul >
                       <li >
                                        <a href="https://siren24-app.web.app/" >
                                          
                                            <FaAmbulance style={{color:'black', fontSize:'30px'}}/>
                                            <span style={{fontSize:'20px', paddingtop:'1px'}}>
                                            &nbsp;Book Now   <span> Ambulace</span>
                                            </span>
                                        </a>
                                    </li>
                           <li>
                               <a className="item-2" href="https://play.google.com/store/apps/details?id=com.yoursuperidea.siren24&hl=en_IN&gl=US">
                                   <i className="fab fa-google-play" />
                                   <span>
                                       Download for <span>Android</span>
                                   </span>
                               </a>
                           </li>
                       </ul>
                       
                       </div>
</div>
<div className="flex-item-left">
 <img src={trainamb} style={{paddingTop:'96px', width:'450px'}}></img>
</div>
</div><br/><br/>

   <ServicesHomeTwo />
   <Allambstabs/>
   <FaqHome/>
   <FooterHomeTwo />
   <BackToTop className="back-to-top-2" />
</>
  )
}
