import React from 'react';

function Forms() {
    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                     Defence colony
                                        <br /> New Delhi-110024
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        (+91) 9910295915
                                        <br />
                                        (+91) 9718459379
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Support</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        support@siren24.in
                                        <br />
                                        csgarg@siren24.in
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <a className="fac" href="https://www.facebook.com/pages/category/Safety---First-Aid-Service/Siren24-108257058227358/">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twi" href="https://twitter.com/Chandra56939310">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="you" href="https://wa.me/%2B919910295915">
                                        <i className="fab fa-whatsapp"></i>
                                    </a>
                                    <a className="lin" href="https://www.linkedin.com/in/siren-ambulance-413a7a219/?originalSubdomain=in">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let’s Connect</h4>
                                <p>We are ready to take your calls 24x7</p>
                                <form action="#" method="post" className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="f-name" placeholder="First Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="l-name" placeholder="Last Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="suject" placeholder="Subject" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="How can we help?"
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                I agree to the <a href="/tnc">Terms & Conditions</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="bisylms-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14015.665098611042!2d77.2232167691448!3d28.57227676361074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce251285f2643%3A0xc4199f6dedf483c6!2sDefence%20Colony%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1654165562344!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe
                    title="map"
                    
                    src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;coord=28.571832,76.23333&amp;q=1%20Defense%20colony%2C%20Delhi%92C%20India+(My%20Business%20Name)&amp;ie=UTF8&amp;t=p&amp;z=16&amp;iwloc=B&amp;output=embed"
                ></iframe>
            </div> */}
        </>
    );
}

export default Forms;
