import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import Drawer from '../Mobile/Drawer';
import DetailsService from './DetailsService';
import HeaderService from './HeaderService';
import HeroService from './HeroService';import { Helmet } from 'react-helmet';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
        <Helmet>
        <title>Siren24 | Terms & conditions</title>
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo/>
            {/* <HeaderService action={drawerAction.toggle} /> */}
            <HeroService />
            <DetailsService />
            {/* <ProjectHomeOne /> */}
             <FooterHomeTwo /> 
            <BackToTop />
        </>
    );
}

export default Service;
