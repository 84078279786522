import React from "react";
import thumb from "../../assets/images/service-details-thumb.jpg";

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30 Employees
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Appie Template
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>quomodosoft.com/appie
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div> */}
            <div className="col-lg-12">
              <div className="service-details-content">
                <div className="thumb">
                  {/* <img src={thumb} alt="" /> */}
                </div>
                <div className="content">
                  <h3 className="title">Refund Policy</h3>
                  <p style={{fontSize:"20px"}}>
                    Charges are calculated immediately upon completion of the
                    journey to the authorized payment method. All charges are
                    non-refundable. This no-refund policy applies at all times,
                    regardless of your decision to stop using the siren24
                    Platform, any interruption of the Siren24 Platform or
                    Services, or any other reason. Siren24 will provide a
                    receipt of the Cancellation Fee, if any, payable by You for
                    each cancellation.
                  </p>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
