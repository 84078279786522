import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import AboutUsTwo from './components/AboutUs/AboutUsTwo';
import Contact from './components/Contact';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import Hometwo from './components/HomeTwo';
import News from './components/News';
import SingleNews from './components/News/SingleNews';
import Service from './components/Service';
import Insurance from './components/Service/Insurance';
import Corporate from './components/Service/Corporate';
import Air from './components/Service/Air';
import Bls from './components/Service/Bls';
import Db from './components/Service/Db';
import Icu from './components/Service/Icu';
import Pt from './components/Service/Pt';
import Train from './components/Service/Train';
import Privacypolicy from './components/Privacypolicy';
import Cancelpolicy from './components/Cancelpolicy';
import Refundpolicy from './components/Refundpolicy';
import Tnc from './components/Tnc';
import Faq from './components/Faq';
import BlogDelhi from './components/News/BlogDelhi';
import BlogGhazi from './components/News/BlogGhazi';
import BlogGurgaon from './components/News/BlogGurgaon';
import BlogFaridabad from './components/News/BlogFaridabad';
import BlogNoida from './components/News/BlogNoida';
import Blog6 from './components/News/Blog6';
import Blog7 from './components/News/Blog7';
import Blog8 from './components/News/Blog8';import Blog12 from './components/News/Blog12';
import Blog9 from './components/News/Blog6';import Blog13 from './components/News/Blog13';
import Blog10 from './components/News/Blog6';import Blog14 from './components/News/Blog14';
import Blog11 from './components/News/Blog6';import Blog15 from './components/News/Blog15';
import AttachFleet from './components/HomeTwo/AttachFleet';
import S24driver from './components/HomeTwo/S24driver';
function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={Hometwo} />
                            <Route exact path="/home" component={Hometwo} />
                            <Route exact path="/news" component={News} />
                            <Route exact path="/news/ambulance-service-at-Delhi" component={BlogDelhi} />
                            <Route exact path="/news/ambulance-service-at-Noida" component={BlogNoida} />
                            <Route exact path="/news/ambulance-service-at-Ghaziabad" component={BlogGhazi} />
                            <Route exact path="/news/ambulance-service-at-Gurgaon" component={BlogGurgaon} />
                            <Route exact path="/news/ambulance-service-at-Faridabad" component={BlogFaridabad} />  
                            <Route exact path="/news/air-ambulance-services-better-for-domestic-patients-international-patients" component={Blog6} />
                            <Route exact path="/news/five-different-type-of-ambulances-in-the-year-2022" component={Blog11} />
                            <Route exact path="/news/best-ambulance-booking-app" component={Blog7} /><Route exact path="/news/ambulance-emergency-number" component={Blog12} />
                            <Route exact path="/news/in-emergency-no-need-to-wait-for-an-hour-when-there-is-siren24" component={Blog8} /><Route exact path="/news/overview-of-omicron-coronavirus" component={Blog13} />
                            <Route exact path="/news/first-aid-in-case-of-emergency" component={Blog9} /><Route exact path="/news/have-you-ever-thought-what-will-happen-if-covid-again-takes-a-sharp-rise-in-our-country" component={Blog14} />
                            <Route exact path="/news/train-ambulance-service-in-india" component={Blog10} /><Route exact path="/news/how-ambulance-patients-can-help-the-third-wave-of-covid-19" component={Blog15} />
                            <Route exact path="/s24driver" component={S24driver} />      
                            <Route exact path="/become-a-driver" component={AttachFleet} />      
                            <Route exact path="/insurance-company-services" component={Insurance} />   
                            <Route exact path="/corporate-services" component={Corporate} />             
                            <Route exact path="/news/single-news" component={SingleNews} />
                            <Route exact path="/service" component={Service} />
                            <Route exact path="/basic-life-support-ambulance" component={Bls} />
                            <Route exact path="/air-ambulance" component={Air} />
                            <Route exact path="/dead-body-ambulance" component={Db} />
                            <Route exact path="/icu-care-ambulance" component={Icu} />
                            <Route exact path="/patient-transport-ambulance" component={Pt} />
                            <Route exact path="/train-ambulance" component={Train} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/privacy-policy" component={Privacypolicy} />
                            <Route exact path="/terms-and-conditions" component={Tnc} />
                            {/* <Route exact path="/faq" component={Faq} /> */}
                            <Route exact path="/cancellation-policy" component={Cancelpolicy} />
                            <Route exact path="/refund-policy" component={Refundpolicy} />
                            <Route exact path="/about-us" component={AboutUsTwo} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
