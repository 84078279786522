import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import Drawer from '../Mobile/Drawer';
import DetailsService from './DetailsService';
import HeaderService from './HeaderService';
import HeroService from './HeroService';import { Helmet } from 'react-helmet';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
        <Helmet>
        <title>Siren24 | Cancellation Policy</title>
        <meta name="title" content="siren24 Cancellation Policy"/>
<meta name="description" content=" Cancellation in case the ambulance has not yet moved towards
                    the pickup truck point or up to 5 minutes of booking time,
                    no charge from User."/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            {/* <HeaderService action={drawerAction.toggle} /> */}
            <HeaderHomeTwo/>
            <HeroService />
            <DetailsService />
            {/* <ProjectHomeOne /> */}
            <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default Service;
