import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-2.png';
import { FaAmbulance } from 'react-icons/fa';
function FooterHomeTwo() {
    return (
        <>
            <section className="appie-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <div className="footer-about-widget footer-about-widget-2">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>
                                Siren24 is a 24*7 Ambulance Booking Service platform. Provides a quick and affordable ambulance at your doorstep.
                                </p>
                               
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/pages/category/Safety---First-Aid-Service/Siren24-108257058227358/">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/Chandra56939310">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://wa.me/%2B919910295915">
                                                <i className="fab fa-whatsapp" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/in/siren-ambulance-413a7a219/?originalSubdomain=in">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation footer-navigation-2">
                                <h4 className="title">Siren24</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/Service">Service</Link>
                                    </li>
                                   
                                    <li>
                                        <Link to="/news">News</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                    <li>
                                        <Link to="/become-a-driver">Become a driver</Link>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation footer-navigation-2">
                                <h4 className="title">Services</h4>
                                <ul>
                                <li>
                                <Link to="/air-ambulance">Air Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/train-ambulance">Train Ambulance</Link>
                            </li>
                                
                            <li>
                                <Link to="/basic-life-support-ambulance">Basic life support </Link>
                            </li>
                            <li>
                                <Link to="/patient-transport-ambulance">Patient transport </Link>
                            </li>
                            <li>
                                <Link to="/icu-care-ambulance">ICU care Ambulance</Link>
                            </li>
                            <li>
                                <Link to="/dead-body-ambulance">Dead body Ambulance</Link>
                            </li>
                           
                                   
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation footer-navigation-2">
                                <h4 className="title">Support</h4>
                                <ul>
                                   
                                    <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                
                                    </li>
                                    <li>
                                    <Link to="/refund-policy">Refund Policy</Link>
                                    </li>
                                    <li>
                                    <Link to="/cancellation-policy">cancellation Policy</Link>
                                    </li>
                                    
                                    <li>
                                    <Link to="/terms-and-conditions">Terms and conditions</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope" /> support@siren24.in
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-phone" /> +919910295915
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
                            >
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="https://siren24-app.web.app/">
                                            <FaAmbulance style={{color:'black', fontSize:'20px'}}/> &nbsp;&nbsp;Book Ambulance
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="https://play.google.com/store/apps/details?id=com.yoursuperidea.siren24&hl=en_IN&gl=US">
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2022 Siren24. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeTwo;
