import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import Drawer from '../Mobile/Drawer';import { Helmet } from 'react-helmet';
import HeaderNews from '../News/HeaderNews';
import HeroNews from '../News/HeroNews';
import Forms from './Forms';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
           <Helmet>
        <title>Siren24 | Contact-us</title>
        <meta name="title" content="siren24 Contact-us"/>
<meta name="description" content=" Siren24 is ready to take your calls 24x7"/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews drawer={drawer} action={drawerAction.toggle} />
            <HeroNews
                title="Contact us"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Contact us' },
                ]}
            />
            <Forms />
            <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default Contact;
