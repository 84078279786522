import React from 'react';
import { Helmet } from 'react-helmet';
import blogImg1 from '../../assets/images/blog/1.png';
import blogImg2 from '../../assets/images/blog/2.jpg';
import blogImg3 from '../../assets/images/blog/3.webp';
import blogImg4 from '../../assets/images/blog/4.png';
import blogImg5 from '../../assets/images/blog/5.png';
import blogImg6 from '../../assets/images/blog/6.jpg';
import blogImg7 from '../../assets/images/blog/7.jpg';
import blogImg8 from '../../assets/images/blog/8.jpg';
import blogImg9 from '../../assets/images/blog/9.png';
import blogImg10 from '../../assets/images/blog/10.jpg';
import blogImg11 from '../../assets/images/blog/11.jpg';
import blogImg12 from '../../assets/images/blog/12.webp';
import blogImg13 from '../../assets/images/blog/13.jpg';
import blogImg14 from '../../assets/images/blog/14.png';
import blogImg15 from '../../assets/images/blog/15.jpg';
const data=[
    {
        image:blogImg1,
        date:"April 22, 2022",
        heading:"How to book an Ambulance Service in Gurgaon at Siren24?",
        ref:"/news/ambulance-service-at-Gurgaon"
    },
    {
        image:blogImg2,
        date:"April 22, 2022",
        heading:" How to book an Ambulance Service in Faridabad at Siren24?",
        ref:"/news/ambulance-service-at-Faridabad"
    } ,
     {
        image:blogImg3,
        date:"April 22, 2022",
        heading:"How to book an Ambulance Service in Ghaziabad at Siren24?",
        ref:"/news/ambulance-service-at-Ghaziabad"
    },
    {
        image:blogImg4,
        date:"April 22, 2022",
        heading:"How to book an Ambulance Service in Delhi at Siren24?",
        ref:"/news/ambulance-service-at-Delhi"
    } ,
    {
        image:blogImg5,
        date:"April 22, 2022",
        heading:"How to book an Ambulance Service in Noida at Siren24?",
        ref:"/news/ambulance-service-at-Noida"
    } ,
    {
        image:blogImg6,
        date:"April 22, 2022",
        heading: "Air Ambulance Services better for Domestic Patients & International Patients",
                ref:"/news/air-ambulance-services-better-for-domestic-patients-international-patients"
    } ,
    {
        image:blogImg7,
        date:"April 22, 2022",
        heading: "The BEST Ambulance Booking App for Any Emergency",        ref:"/news/best-ambulance-booking-app"
    } ,
    {
        image:blogImg8,
        date:"April 22, 2022",
        heading: "Emergency No Need To Wait for an hour, When there is Siren24",
        ref:"/news/in-emergency-no-need-to-wait-for-an-hour-when-there-is-siren24"
    } ,
    {
        image:blogImg9,
        date:"April 22, 2022",
        heading: "How to give first aid in case of emergency",
        ref:"/news/first-aid-in-case-of-emergency"
    } ,
    {
        image:blogImg10,
        date:"April 22, 2022",
        heading: "Train ambulance in India",
        ref:"/news/train-ambulance-service-in-india"
    } ,
    {
        image:blogImg11,
        date:"April 22, 2022",
        heading: "Five Different type of ambulances in the year 2022",
        ref:"/news/five-different-type-of-ambulances-in-the-year-2022"
    } ,
    {
        image:blogImg12,
        date:"April 22, 2022",
        heading: "Ambulance Emergency Number in India",
        ref:"/news/ambulance-emergency-number"
    } ,
    {
        image:blogImg13,
        date:"April 22, 2022",
        heading: "An Overview of the Omicron Coronavirus",
        ref:"/news/overview-of-omicron-coronavirus"
    } ,
    {
        image:blogImg14,
        date:"April 22, 2022",
        heading: "what will happen if Covid again takes a sharp rise in our country and Ambulance?",
        ref:"/news/have-you-ever-thought-what-will-happen-if-covid-again-takes-a-sharp-rise-in-our-country"
    } ,
    {
        image:blogImg15,
        date:"April 22, 2022",
        heading: "How Ambulance Patients Can Help the Third Wave of COVID-19",
        ref:"/news/how-ambulance-patients-can-help-the-third-wave-of-covid-19"
    } ,
]
function Blogs() {
    return (
        <>
        
        <Helmet>
        <title>Siren24 | News</title>
      </Helmet>
        
            <div className="row">
            {
                data.map((item)=>
                <div className="col-lg-6">
                <div className="post-item-1">
                    <img src={item.image} alt="" />
                    <div className="b-post-details">
                        <div className="bp-meta">
                            <a href="#">
                                <i className="fal fa-clock"></i>{item.date}
                            </a>
                            <a href="#">
                                <i className="fal fa-comments"></i>6 Comments
                            </a>
                        </div>
                        <h3>
                            <a href={item.ref}>
                            {item.heading}
                            </a>
                        </h3>
                        <a className="read-more" href={item.ref}>
                            Read More<i className="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
                )
            }
                {/* <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg2} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2022
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href={ref}>
                                   How to book an Ambulance Service in Faridabad at Siren24?
                                </a>
                            </h3>
                            <a className="read-more" href={ref}>
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg3} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2022
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/blogGhazi">
                                How to book an Ambulance Service in Ghaziabad at Siren24?
                                </a>
                            </h3>
                            <a className="read-more" href="/news/blogGhazi">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg4} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2022
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/blogDelhi"> How to book an Ambulance Service in Delhi at Siren24?</a>
                            </h3>
                            <a className="read-more" href="/news/blogDelhi">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg5} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="/news/blogNoida">
                                    <i className="fal fa-clock"></i>April 22, 2022
                                </a>
                                <a href="/news/blogNoida">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/blogNoida">
                                How to book an Ambulance Service in Noida at Siren24?
                                </a>
                            </h3>
                            <a className="read-more" href="/news/blogNoida">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg6} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2022
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/single-news">
                                    Build A Full Web Chat App From Scratch.
                                </a>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg7} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2022
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="single-post.html">Learning Python for Data Analysis.</a>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg8} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2022
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="single-post.html">Learning Python for Data Analysis.</a>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {/* <div className="bisylms-pagination">
                        <span className="current">01</span>
                        <a href="#">02</a>
                        <a className="next" href="#">
                            next<i className="fal fa-arrow-right"></i>
                        </a>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default Blogs;
