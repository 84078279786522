import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';import { Helmet } from 'react-helmet';
import Drawer from '../Mobile/Drawer';
import Blogs from './Blogs';
import BlogSideBar from './BlogSideBar';
import HeaderNews from './HeaderNews';
import HeroNews from './HeroNews';

function News() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
         <Helmet>
        <title>Siren24 | News</title>
        <meta name="title" content="siren24 ambulance latest news"/>
<meta name="description" content="Latest and Breaking News on ambulance. Explore ambulance profile at Siren24 for Blogs, videos and latest news of ambulance."/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service, ambulance number, ambulance news"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>

            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews action={drawerAction.toggle} />
            <HeroNews
                title="News"
                breadcrumb={[
                    { link: '/home', title: 'home' },
                    { link: '/news', title: 'News' },
                ]}
            />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-7">
                            <Blogs />
                        </div>
                      
                    </div>
                </div>
            </section>
            <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default News;
