import React from 'react';
import BackToTop from '../BackToTop';
import FooterHomeTwo from '../HomeTwo/FooterHomeTwo';
import Drawer from '../Mobile/Drawer';import ShareIcons from './ShareIcons';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import HeroNews from './HeroNews';import { Helmet } from 'react-helmet';
function Blog() {
   
    return (
        <>
                <Helmet>
        <title>Siren24 | How to book an Ambulance Service in Noida at Siren24? </title>
        <meta name="title" content="How to book an Ambulance Service in Noida at Siren24?"/>
<meta name="description" content="Book any type of ambulance at Noida, that is needed as per your requirment and patient's condition, with Siren24. "/>
<meta name="keywords" content="ambulance, ambulance app, ambulance service, ambulance number, ambulance news,ambulance service at Noida"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"></meta>
      </Helmet>
            <HeaderHomeTwo />
            <HeroNews
                title="How to book an Ambulance Service in Noida at Siren24?"
                breadcrumb={[
                    { link: '/home', title: 'home' },
                    { link: '/news', title: 'Blogs' },
                ]}
            /><br/><br/><br/>
            <div className="container">
              <p>
              New Okhla Industrial Development Authority (Noida) is a planned city in Uttar Pradesh and is a part of Delhi-NCR. It is considered one of the cleanest and greenest medium population cities in India. The physical infrastructure development in Noida is also on the higher end as compared to its counterparts in various sectors like - technology, education, housing, health, etc. To contribute to the health infrastructure development, Siren24 has come up with a 24*7 Ambulance Service in Noida which is speedy and affordable.

During the pandemic, there was a huge drift of patients from Delhi to its neighbouring regions due to a reduction in resources in Delhi. Noida not only serves as a great place for the education and technology sector but also for health. It has various hospitals like - Metro Group of Hospital, Jaypee Hospital, Apollo Hospital, etc. Siren24 can help in times of non-emergency like hospital visits with basic medical support and emergencies like - cardiac arrest, severe accidents, and fractures, etc. It can provide a quick ambulance service equipped with all the necessary medical equipment and health professionals.

                  </p><br/>
                {/* <div className="post-thumb">
                    <img src={singlePost} alt="" />
                </div> */}
                <h4 className="article-title">How to book an Ambulance Service in Noida at Siren24?</h4>
                <p>
                Siren24 has various types of ambulances to choose from - Basic/Non-emergency ambulance, ICU/Ventilator Ambulance, Train/Air Ambulance, etc. The procedure to book an ambulance is hassle-free and can be done in any of the 3 ways -
1.	Via call - +919910295915
2.	Via Siren24 app, which is available on Android and iOS both and is free to download. After installing the app, you have to register with your contact number, then put in the pickup and drop-off location.
3.	Via Siren24 website 

The app and the website are also equipped with live location tracking.

The patient can be taken from home to hospital or from one hospital to another hospital. Post-treatment patients who require basic support during regular checkups can also be helped through our ambulances. 

Trust us with your loved ones getting the treatment on time!

                </p>
            
                {/* <blockquote>
                    <p>
                        I don't want no agro brilliant are you taking the piss skive off super boot
                        chancer don't get shirty.
                    </p>
                    <cite>Indigo Violet</cite>
                </blockquote> */}

                
               <ShareIcons/>
                <br/><br/>
            </div>
            
            <div className="comment-form-area container py-md-6">
                <h3>Post a Comment</h3>
                <p>Your email address will not be published. Required fields are marked</p>
                <div className="comment-form">
                    <form className="row" action="#" method="post">
                        <div className="col-md-6">
                            <input type="text" name="name" placeholder="Name" />
                        </div>
                        <div className="col-md-6">
                            <input type="email" name="email" placeholder="Email" />
                        </div>
                        <div className="col-md-12">
                            <input type="url" name="url" placeholder="Website" />
                        </div>
                        <div className="col-md-12">
                            <div className="save-comment">
                                <input id="history-name" type="checkbox" />
                                <label htmlFor="history-name">
                                    Save my name, email, and website in this browser for the next
                                    time I comment.
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <textarea placeholder="Coment"></textarea>
                        </div>
                        <div className="col-md-12">
                            <button type="submit">Post Comment</button>
                        </div>
                    </form>
                </div>
            </div><br/><br/><br/>
            <div className="comment-form-area container py-md-6">
                <h3>Post a Comment</h3>
                <p>Your email address will not be published. Required fields are marked</p>
                <div className="comment-form">
                    <form className="row" action="#" method="post">
                        <div className="col-md-6">
                            <input type="text" name="name" placeholder="Name" />
                        </div>
                        <div className="col-md-6">
                            <input type="email" name="email" placeholder="Email" />
                        </div>
                        <div className="col-md-12">
                            <input type="url" name="url" placeholder="Website" />
                        </div>
                        <div className="col-md-12">
                            <div className="save-comment">
                                <input id="history-name" type="checkbox" />
                                <label htmlFor="history-name">
                                    Save my name, email, and website in this browser for the next
                                    time I comment.
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <textarea placeholder="Coment"></textarea>
                        </div>
                        <div className="col-md-12">
                            <button type="submit">Post Comment</button>
                        </div>
                    </form>
                </div>
            </div><br/><br/><br/>
            <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default Blog;
