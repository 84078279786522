import React from 'react';
import heroMan from '../../assets/images/hero-mans.png';
import heroThumb from '../../assets/images/hero-thumb-3.png';
import shapeTen from '../../assets/images/shape/shape-10.png';
import shapeEleven from '../../assets/images/shape/shape-11.png';
import shapeTwelve from '../../assets/images/shape/shape-12.png';
import shapeNine from '../../assets/images/shape/shape-9.png';
import { FaAmbulance } from 'react-icons/fa';
function HeroHomeTwo() {
    return (
        <>
            <section className="appie-hero-area-2 mb-90 ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="appie-hero-content-2">
                                
                                <h1 className="appie-title">
                                Your One Step Solution For 24*7 Ambulance Service  <span>At Your Doorstep!</span>
                                </h1>
                                <div className='appie-hero-content appie-download-content'>
                                <ul >
                                    <li >
                                        <a href="https://siren24-app.web.app/" >
                                          
                                            <FaAmbulance style={{color:'black', fontSize:'30px'}}/>
                                            <span style={{fontSize:'20px', paddingtop:'1px'}}>
                                            &nbsp;Book Now   <span> Ambulace</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="https://play.google.com/store/apps/details?id=com.yoursuperidea.siren24&hl=en_IN&gl=US">
                                            <i className="fab fa-google-play" />
                                            <span>
                                                Download for <span>Android</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                                
                                </div>
                                <div className="hero-users">
                                    <img src={heroMan} alt="" />
                                    <span>
                                        30k <span> Feedbacks</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="appie-hero-thumb-3 wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="400ms"
                >
                    <img className='hpmob' src={heroThumb} alt="" />
                </div>
                <div className="hero-shape-1">
                    <img src={shapeNine} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeTen} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeEleven} alt="" />
                </div>
                <div className="hero-shape-4">
                    <img src={shapeTwelve} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeTwo;
